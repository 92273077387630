import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "subscriptions",

    async GET_invoicePlan(subscription_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/invoice-plan/${subscription_id}`,
            { params: params }
        );
    },

    PUT_relive(subscription_id, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/relive/${subscription_id}`,
            {},
            { params: params }
        );
    },

    PUT_massEdit(data, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/massEdit`,
            data,
            { params: params }
        );
    },
    /**
     * Close the subscription immediately and change status to 2.
     * @param {} subscription_id
     */
    async closeNow(subscription_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/close-now/${subscription_id}`
        );
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};
